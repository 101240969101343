import React from "react"
import '../assets/fonts/Poppins/Poppins-Light.ttf';
import '../assets/fonts/Poppins/Poppins-Bold.ttf';
import '../assets/fonts/Poppins/Poppins-Regular.ttf';
import "../components/layout.css";

import Produtividade from "../components/Cursos/Produtividade"
const CursoProdutividadePage = () => {
  return (
    <Produtividade/>
  )
}

export default CursoProdutividadePage