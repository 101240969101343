import React, { useEffect, useState } from "react"
import SEO from "../../seo"
import {
  URL_EMAIL,
  URL_HOTMART_CURSO_HABITOS,
  URL_INSTA_FLYNOW,
  URL_WHATSAPP,
} from "../../../constants"
import { getQueryParam, getTrackingHotmart } from "../../../utils/query"
import { getTextCupom, formatToMoney } from "../../../utils/cupom"
import styled from "styled-components"
import colors from "../../../colors"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { Description, Row, TitleMain } from "../../Ebooks"
import FAQ from "../../FAQ"
const primary = colors.primary
const imageBackground = require("./background.png")

export const H1 = styled.h1`
  text-align: center;
  font-size: 2.7rem;
`
export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
`
const Space = styled.div`
  margin-top: 0.5rem;
`
export const ItemContact = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
`

const Highlight = styled.span`
  color: ${primary};
`

export const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${primary};
`

export const P = styled.p`
  font-size: 1.1rem;
  color: ${colors.regular};
`

const Button = styled.a`
  display: block;
  text-align: center;
  padding: 18px 50px;
  color: white;
  background: ${primary};
  border-radius: 40px;
  font-family: "Poppins-Bold";
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  width: 500px;
  text-decoration: none;
  @media (max-width: 600px) {
      width: 85%;
    }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  min-height: 100vh;
  background-image: url(${imageBackground});
  background-repeat: no-repeat;
  padding: 2rem;
  background-size: cover;

  .main-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    h1 {
      text-shadow: 3px 1px 9px rgba(0, 0, 0, 0.45);
    }
    span {
      text-decoration: underline;
    }
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 18rem;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
`

export const Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 15%;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ background }) =>
    background && `background: ${background};`}

  @media (max-width: 768px) {
    padding: 2rem 10%;
  }

  .icon {
    font-size: 10rem;
    color: ${colors.textStrong};
    cursor: pointer;
    transition: all 0.4s linear;
  }
  .icon:hover {
    color: ${colors.primary2};
  }
  input {
    padding: 15px;
    border: 5px solid ${colors.surface};
    border-radius: 12px;
    color: ${colors.primary2};
    margin-bottom: 20px;
    font-size: 1.1rem;
    min-width: 280px;
    max-width: 97%;
  }
  input:focus {
    outline: none !important;
    border-color: ${colors.surface};
    box-shadow: 0 0 10px ${colors.surface};
  }

  .button-waiting-list {
    padding: 15px 50px;
    background: ${colors.green};
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.1rem;
    font-family: "Poppins-Bold";
  }
  .button-waiting-list:hover {
    transition: all 0.4s linear;
    box-shadow: 0 0 10px ${colors.green};
  }
  a {
    text-decoration: none;
  }
`

export const contact = [
  {
    link: URL_WHATSAPP,
    img: <FaWhatsapp className="icon" />,
    description: "31 999107753",
  },
  {
    link: URL_EMAIL,
    img: <MdMailOutline className="icon" />,
    description: "support@appflynow.com",
  },
  {
    link: URL_INSTA_FLYNOW,
    img: <FaInstagram className="icon" />,
    description: "@appflynow",
  },
]

const IconAndLabel = ({icon, label})=> {
  return (
    <div style={{margin: 20}}>
      <H2>{icon}</H2>
      <P>{label}</P>
    </div>
  )
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0;
`

export default function ProdutividadeB() {
  const [cupom, setCupom] = useState(null)
  const [production] = useState(true)
  const [utm, setUtm] = useState("")
  const [email, setEmail] = useState("")
  const [test] = useState(new Date().getTime() % 2 === 0 ? "A" : "B")
  const [response, setResponse] = useState({ loading: false })
  const vslA = "https://www.youtube-nocookie.com/embed/5D_jM-gbMps?autoplay=1"
  const vslB = "https://www.youtube-nocookie.com/embed/BcQqsvg6cAc?autoplay=1"

  function getUrlCheckout() {
    let url = URL_HOTMART_CURSO_HABITOS
    if (cupom) {
      url = url.concat("?offDiscount=" + cupom)
    }
    if (utm) {
      const prefix = url.includes("?") ? "&" : "?"
      url = url.concat(prefix + "sck=" + getTrackingHotmart())
    }
    return url
  }

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_course_habit")
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
    // setProduction(getQueryParam("production"))
  }, [])

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 179.9
    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "247,99",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
          (discontPercentage
            ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
            : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const onSubmit = () => {
    if (!email) {
      return alert("Informe o seu email")
    }
    if (!validateEmail(email)) {
      return alert("Informe um email válido")
    }
    sendEvent("click_waiting_list_course_habit")
    setResponse({ loading: true })
    // sendEvent('click_get_course_habit')
    fetch("https://api.appflynow.com/waiting-list-course-habit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        test,
        cupom,
        utm_source: getQueryParam("utm_source"),
        utm_medium: getQueryParam("utm_medium"),
        utm_campaign: getQueryParam("utm_campaign"),
      }),
    })
      .then(res => {
        if (res.status === 200) {
          setResponse({ status: res.status, loading: false })
          alert(
            "Pronto! Te enviaremos um e-mail com um link promocional quando o curso estiver disponível!"
          )
        } else {
          res.json().then(data => {
            setResponse({
              loading: false,
              status: res.status,
              message: data.message,
            })
            alert(data.message)
          })
        }
      })
      .catch(err => {
        setResponse({ loading: false, message: err.message, status: 400 })
      })
  }

  const onCheckout = () => {
    sendEvent("click_checkout_course_habit")
  }

  return (
    <>
      <SEO
        title="A sua vida extraordinária começa mudando seus hábitos"
        pageId="course_habitos"
        description="Você já se perguntou por que algumas pessoas alcançam o sucesso enquanto outras ficam presas em uma rotina de mediocridade? A resposta está nos hábitos! Os hábitos são a base de nossas ações diárias e, quando dominados, podem levar a uma vida extraordinária. | Curso sobre como criar bons hábitos e eliminar hábitos ruins"
        imageUrl={require("../../../assets/images/garantia7dias.png")}
      />
      <Main>
        <div className="main-content">
          <TitleMain center color="#fff">
          Você não precisa de mais <Highlight>horas</Highlight> no seu dia. Você precisa aprender a <Highlight>ser produtivo</Highlight>.
          </TitleMain>
          <Description color="#ddd" style={{ marginBottom: 10 }}>
          Conheça o guia que vai te ajudar a <b>azer seu dia render, gerenciar melhor seu tempo</b> e <b>vencer o ciclo vicioso da procrastinação</b>.
          </Description>
          {textCupom && (
            <h4
              style={{
                background: colors.red,
                color: "#fff",
                textAlign: "center",
                padding: 5,
                marginTop: 10,
                marginRight: 10,
                marginBottom: 20
              }}
            >
              {textCupom}
            </h4>
          )}
          <Button
            href={URL_CHECKOUT}
            onClick={onCheckout}
            style={{marginTop: 15}}
          >
          QUERO SER PRODUTIVO
          </Button>
        </div>
      </Main>

      <Section backgroundColor={colors.surface}>
        <H2>
        Se você está cansado de...
        </H2>
        <br />
        <FlexWrap>
          <IconAndLabel icon={"❌"} label={<>Seu dia <b>não render</b></>} />
          <IconAndLabel icon={"❌"} label={<>Viver <b>procrastinando</b> tudo</>} />
          <IconAndLabel icon={"❌"} label={<>Sua <b>rotina</b> ser um <br/> verdadeiro desastre</>} />
          <IconAndLabel icon={"❌"} label={<>Perder <b>oportunidades</b><br/> e ficar <b>estagnado</b></>} />
          <IconAndLabel icon={"❌"} label={<>Não conseguir <b>alcançar<br/> seus sonhos</b></>} />
        </FlexWrap>
      </Section>

      <Section>
        <H2>
        E quer...
        </H2>
        <br />
        <FlexWrap>
          <IconAndLabel icon={"❌"} label={<>Seu dia <b>não render</b></>} />
          <IconAndLabel icon={"❌"} label={<>Viver <b>procrastinando</b> tudo</>} />
          <IconAndLabel icon={"❌"} label={<>Sua <b>rotina</b> ser um <br/> verdadeiro desastre</>} />
          <IconAndLabel icon={"❌"} label={<>Perder <b>oportunidades</b><br/> e ficar <b>estagnado</b></>} />
          <IconAndLabel icon={"❌"} label={<>Não conseguir <b>alcançar<br/> seus sonhos</b></>} />
        </FlexWrap>
      </Section>

      <Section backgroundColor={colors.surface}>
        <H2>O que você vai aprender? 🧠</H2>
        <br />
        <img
          src={require("../../../assets/images/cursoHabitos.png")}
          alt="curso-domine-seus-habitos"
          style={{ height: 150 }}
        />
        <br />
        <P>
          <b>Módulo 1 - Desvendando os Hábitos: </b>
          Aprenda o que são os hábitos e mergulhe nos mistérios por trás da sua
          formação.
        </P>

        <P>
          <b>Módulo 2 - Táticas Infalíveis para Criar Hábitos: </b>
          Conheça 7 técnicas poderosas para criar hábitos duradouros.
        </P>
        <P>
          <b>Módulo 3 - Motivação & Disciplina: </b>
          Desvende os segredos da motivação e aprenda a mantê-la acesa durante
          todo o processo de criação de hábitos.
        </P>
        <P>
          <b>Módulo 4 - Rotinas que Transformam: </b>
          Descubra como criar rotinas matinais e noturnas que estabeleçam uma
          base sólida para hábitos saudáveis.
        </P>
        <P>
          <b>Módulo 5 - Resenha de livros sobre hábitos: </b>
          Explore a fundo o tema com a sabedoria dos 6 livros-chave sobre
          hábitos.
        </P>
        <P style={{ width: "100%" }}>
          <b>Módulo 6 - Aulas Bônus: </b>
          <ul>
            <li>8 Dicas para Ser Mais Produtivo</li>
            <li>5 Passos para um Planejamento Diário Eficiente</li>
            <li>Como Usar o App Flynow para Criar Hábitos</li>
          </ul>
        </P>
        <P>
          <b>🔥 Bônus extra POR TEMPO LIMITADO: E-book Guia Completo sobre Hábitos </b> <br />
          Adquirindo nosso curso HOJE, você também ganha, como bônus, nosso e-book{" "}
          <b>Guia Completo sobre Hábitos</b> que, além de diversos conteúdos,
          conta com <b>2 PDFs</b> para você colocar em prática ainda mais seu
          conhecimento.
        </P>
        <br />
        <h3><div style={{backgroundColor: primary, padding: 12, color: 'white'}}>ADQUIRA AGORA MESMO 👇🏻</div></h3>
        <br />
        <>
          <h2>
            <s>DE R$ {oldPrice}</s>
          </h2>
          {price !== newPrice && (
            <>
              <h2>
                POR <s>R$ {price}</s>
              </h2>
              <h4
                style={{
                  background: "#dd0000",
                  color: "#fff",
                  textAlign: "center",
                  margin: 5,
                  padding: 5,
                }}
              >
                + {discontPercentage}% OFF
              </h4>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: "4px solid " + primary,
              alignItems: "center",
              color: primary,
              padding: 7,
              marginTop: 5
            }}
          >
            <h6>POR APENAS 12x DE </h6>
            <h1 style={{ margin: 10 }}> R$ {formatToMoney((Number(newPrice.replace(',','.'))*1.1973)/12)} </h1>
            <h6> ou {newPrice} à vista </h6>
          </div>
          <br />
        <Button href={URL_CHECKOUT} onClick={onCheckout}>QUERO GARANTIR MEU CURSO!</Button>
        </>
        
      </Section>

      <FAQ
        data={[
          {
            title: "Como é o formato do curso?",
            text:
              "Aulas gravadas, que você pode assistir quando e onde quiser. :)",
          },
          {
            title: "Quantos módulos tem o curso?",
            text: (
              <>
                6 módulos, sendo divididos em: <br/><br/>
                <li>
                <b>Módulo 1</b>: Aprenda
                o que são os hábitos e como eles se formam.
                </li>
                <li>
                <b>Módulo 2</b>: Conheça 7
                técnicas poderosas para criar hábitos duradouros.
                </li>
                <li>
                <b>Módulo 3</b>:
                Entenda o que é a motivação e a disciplina e como você pode
                usá-las no processo de criação de hábitos.
                </li>
                 <li>
                 <b>Módulo 4</b>: Descubra
                como criar rotinas que estabeleçam uma base sólida para seus
                hábitos saudáveis.
                 </li>
                 <li>
                 <b>Módulo 5</b>: Explore a fundo o tema com a
                 sabedoria dos 6 livros-chave sobre hábitos.
                 </li>
                 <li>
                 <b>Módulo 6</b>: Aulas
                bônus para complementar seu aprendizado. 
                 </li>
                 <br/>
                 Além do e-book Guia
                Completo sobre Hábitos também como bônus.
              </>
            ),
          },
          {
            title: "Qual é a duração total do curso?",
            text:
              "O curso contém 26 aulas, que correspondem a 3 horas e 18 minutos de contéudos.",
          },
          {
            title: "Quais são as opções de pagamento?",
            text:
              "O curso pode ser pago via cartão de crédito, pix ou saldo de conta Hotmart e você também pode parcelá-lo em até 12x. Ah, e se você for usuário premium de alguns dos nossos apps, você ainda conta com um desconto de 40% no valor do curso!",
          },
          {
            title: "E se eu não gostar do curso?",
            text:
              "Caso você não fique completamente satisfeito com o curso, basta nos avisar dentro de sete dias após a compra e nós devolveremos seu dinheiro. Simples assim!",
          },
        ]}
      />

      <Section backgroundColor={colors.surface}>
        <H2>Garantia incondicional de 7 dias ✅</H2>
        <br />
        <img
          src={require("../../../assets/images/garantia7dias.png")}
          alt="garantia-7-dias"
          style={{ height: 250 }}
        />
        <div>
          <P>
            Acreditamos tanto na qualidade e no impacto transformador do nosso
            curso que queremos eliminar qualquer dúvida ou receio que você possa
            ter.
          </P>
          <P>
            É por isso que estamos oferecendo uma{" "}
            <b>garantia incondicional de 7 dias.</b>
          </P>
          <P>
            Isso mesmo, caso você não fique completamente satisfeito com o
            curso, basta nos avisar dentro de sete dias após a compra e nós{" "}
            <b>devolveremos seu dinheiro. </b> Simples assim!
          </P>
        </div>
        <br />
      </Section>

      <Section>
        <H2>Alguma dúvida?</H2>
        <br />
        <P>Se tiver qualquer dúvida entre em contato conosco!</P>
        <Contact>
          {contact.map(i => (
            <ItemContact key={i.link}>
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                {i.img}
                <h4 style={{ color: colors.textStrong }}>{i.description}</h4>
              </a>
            </ItemContact>
          ))}
        </Contact>
        <br />
      </Section>
    </>
  )
}
